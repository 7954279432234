/* eslint-disable */
import React, { useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import i18next from "i18next";
import FurtherInformationButton from "../../components/buttons/FurtherInformationButton";
import DoughnutChart from "../../components/charts/DoughnutChart";

/**
 * Final view of role flow. Contains of diagram and multiple navigation options.
 * @param {useRef} showScanIcon - Set visibility of scan icon.
 * @param {html} saveARSystem - The AR System.
 * @param rating - Selected user rating
 * @param {[]} selectedAction - Contains all params of current action.
 * @param {[]} activeRole - Contains all params of current role.
 * @param questionArray - Array containing all questions of a role
 * @return {JSX.Element}
 * @constructor
 */
const EndScreenRole = ({showScanIcon, saveARSystem, rating, selectedAction, activeRole, questionArray}) => {

    const {t} = useTranslation();

    const {answerPositive} = selectedAction;
    const {answerNegative} = selectedAction;

    /**
     * Create badge with user rating from previous screen
     * @param rated - Rating given by user
     * @return {JSX.Element}
     * @constructor
     */
    const AnswerBadge = (rated) => {
        let reply;
        let explanation;

        switch (rated) {
            case 'veryPositive':
                reply =  t('rating.veryPositive');
                explanation = <Pos/>;
                break;
            case 'positive':
                reply = t('rating.positive');
                explanation = <Pos/>;
                break;
            case 'negative':
                reply = t('rating.negative');
                explanation = <Neg/>;
                break;
            case 'veryNegative':
                reply =  t('rating.veryNegative');
                explanation = <Neg/>;
                break;
        }
        return (
            <>
                <span className={`btn-purple-small ${rating}`}>{reply}</span>
                {explanation}
            </>
        )
    }

    /**
     * set background color and change color of veryPositive answer badge to suit the background color
     */
    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'var(--yellow)';

        let veryPositivClass = document.querySelector('.veryPositive')
        if(veryPositivClass){
            veryPositivClass.style.color =  'var(--white)' ;
            veryPositivClass.style.background = 'var(--green)' ;
        }
    }, []);

    /**
     * Additional information for positive voting.
     * @return {JSX.Element}
     * @constructor
     */
    const Pos = () => {
        let answer = answerPositive[Math.floor(Math.random() * answerPositive.length)];
        return (
            <>
                <p>→ {answer}</p>
            </>
        )
    }

    /**
     * Additional information for negative voting.
     * @return {JSX.Element}
     * @constructor
     */
    const Neg = () => {
        let answer = answerNegative[Math.floor(Math.random() * answerNegative.length)];
        return (
            <>
                <p>→{answer}</p>
            </>
        )
    }

    /**
     * Answer next question of role play.
     * @return {JSX.Element}
     * @constructor
     */
    const NextQuestion = () => {
        return (
            <>
                <div className={'card-explanation relative'}>
                    <p>{t('endScreen.thanks')}</p>
                    <p>{t('endScreen.anotherOne')}</p>
                    <div >
                        <Link to={'/about-role/role-rating'}
                              className={'btn btn-purple'}>{t('handleRole.button')}</Link>
                    </div>
                </div>
            </>
        )
    }

    /**
     * Send user back to scanningView to search next person.
     * @return {JSX.Element}
     * @constructor
     */
    const LastQuestion = () => {
        return (
            <>
                <div className={'card-explanation relative'}>
                    <p>{t('endScreen.thanks')}</p>
                    {activeRole.map((role) => {
                        return (
                            <p key={0}>{role[i18next.language].lastQuestion}</p>

                        )
                    })}
                </div>
            </>
        )
    }

    return (
        <>
            <div className={'container-end space-between vh-100 bg-yellow scrollbar'}>
                <div className={' reply-badge'}>
                    <p>{t('endScreen.yourVote')}</p>
                    {AnswerBadge(rating)}
                </div>
                <p>{t('endScreen.othersVote')}</p>
                <DoughnutChart num={selectedAction.num} fromRole={true}/>
                {questionArray.length === 0 ?  <LastQuestion/> : <NextQuestion/> }
                <div className={'btn-set-col '}>
                    <Link to={'/search'} onClick={() => {showScanIcon.current = true; saveARSystem.unpause()}} className={'btn btn-purple'}>{t('button.start')}</Link>
                    <Link to={'/evaluation-flow'} className={'btn btn-purple'}>{t('button.evaluation')} </Link>
                </div>
                <FurtherInformationButton t={t}/>
            </div>
        </>
    );
};

export default EndScreenRole;
