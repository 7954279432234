/* eslint-disable */
import React, {useEffect, useState} from 'react';
import ScanningVIewNavigation from "../../components/arElements/ScanningVIewNavigation";
import {useTranslation} from "react-i18next";
import Modal from "../../components/modal/Modal";

import adaptation from '../../assets/marker/adaptation.png'
import mobility from '../../assets/marker/mobility.png'
import industry from '../../assets/marker/industry.png'
import consumption from '../../assets/marker/consumption.png'
import justice from '../../assets/marker/justice.png'
import energy from '../../assets/marker/energy.png'
import biodiversity from '../../assets/marker/biodiversity.png'
import martin from '../../assets/marker/martin.png'
import carla from '../../assets/marker/carla.png'
import urs from '../../assets/marker/urs.png'
import sabine from '../../assets/marker/sabine.png'
import nutrition from '../../assets/marker/nutrition.png'
import finances from '../../assets/marker/finance.png'
import lupe from '../../assets/img/lupe.svg'
import party from '../../assets/img/party.svg'

/**
 * This view contains of a navigation bar to find the next marker and a help window.
 * @param index - Array position to pass as prop
 * @param setIndex - Set array position
 * @param {function} setIntroStepsCount - Set current intro step.
 * @param {number} introStepsCount - The current intro step.
 * @param {useRef} showScanIcon - Set visibility of scan icon.
 * @param {html} saveARSystem - The AR System.
 * @param {boolean} started - If AR Overlay is visible.
 * @param {[]} newRefData - Data of navigation bar and scanning image.
 * @param {function} setFoundSection - Set selected Section.
 * @param {function} setFoundRole - Set selected character.
 * @return {JSX.Element}
 * @constructor
 */
const ScanningView = ({
                          index,
                          setIndex,
                          setQuestionArray,
                          setIntroStepsCount,
                          introStepsCount,
                          showScanIcon,
                          saveARSystem,
                          started,
                          newRefData,
                          setFoundSection,
                          setFoundRole,
                          gamificationArray,
                          totalGamificationElements
                      }) => {

    console.log("render search view")
    const {t} = useTranslation();

    const [helpWindowIsOpen, setHelpWindowIsOpen] = useState(false);

    // data array used for navigation bar and image
    const refData = [
        {
            json: 'section.nutrition',
            marker: 'nutrition',
            type: 'section',
            img: nutrition
        },
        {
            json: 'section.mobility',
            marker: 'mobility',
            type: 'section',
            img: mobility
        },
        {
            json: 'section.industry',
            marker: 'industry',
            type: 'section',
            img: industry
        },
        {
            json: 'section.consumption',
            marker: 'consumption',
            type: 'section',
            img: consumption
        },
        {
            json: 'section.energy',
            marker: 'energy',
            type: 'section',
            img: energy
        },
        {
            json: 'section.adaptation',
            marker: 'adaptation',
            type: 'section',
            img: adaptation
        },
        {
            json: 'section.justice',
            marker: 'justice',
            type: 'section',
            img: justice
        },
        {
            json: 'section.biodiversity',
            marker: 'biodiversity',
            type: 'section',
            img: biodiversity
        },
        {
            json: 'section.finances',
            marker: 'finances',
            type: 'section',
            img: finances
        },
        {
            name: "Carla Rossi",
            marker: 'Carla Rossi',
            type: 'role',
            img: carla
        },
        {
            name: "Urs Schneider",
            marker: 'Urs Schneider',
            type: 'role',
            img: urs
        },
        {
            name: "Sabine Müller",
            marker: 'Sabine Müller',
            type: 'role',
            img: sabine
        },
        {
            name: "Martin Leroy",
            marker: 'Martin Leroy',
            type: 'role',
            img: martin
        }
    ]

    /**
     * Shuffle data array for to generate random order.
     * source: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
     * @param array - Data array used for navigation bar and scanning image.
     * @return {*}
     */
    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    /**
     * Shuffle data array with marker information when component is first mounted
     * and increase intro steps depending on how many times this component is mounted.
     */
    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'transparent';
        console.log("on mount search")

        // shuffle array to change order each time AR screen is opened
        if (introStepsCount === 3) {
            newRefData.current = shuffleArray(refData);
            setIntroStepsCount(introStepsCount + 1) // render ARNavigation component if introStepsCount > 3
        }
        // increase intro step by 1, if component is mounted second time --> will disables instruction box
        if (introStepsCount === 4) {
            setIntroStepsCount(introStepsCount + 1)
        }
        // show scan icon
        showScanIcon.current = true;

        return () => {
            console.log("destroy")
            saveARSystem.pause(true); // terminate marker recognition
            showScanIcon.current = false;
        }
    }, []);

    /**
     * Add image of current measure/character to mindAR scanner icon.
     */
    useEffect(() => {
        const scannerWrapper = document.querySelector('.scanning')
        scannerWrapper.style.position = 'relative'
        const scannerIcon = document.querySelector('.inner')
        scannerIcon.style.zIndex = '10'
        const image = document.querySelector('.scanner-img')

        if (image !== null) {
            image.style.backgroundImage = 'url(' + newRefData.current[index].img + ')'
        } else {
            let img = document.createElement("div")
            img.classList.add("scanner-img")
            img.style.backgroundImage = 'url(' + newRefData.current[index].img + ')'
            img.style.backgroundSize = 'cover'
            img.style.backgroundRepeat = 'no-repeat'
            scannerWrapper.appendChild(img)
        }
    }, [started, index])

    /**
     * Render component after image array is set
     */
    useEffect(() => {
    }, [introStepsCount, gamificationArray])

    useEffect(()=>{
        setQuestionArray([]);
        console.log('question array set')
    }, [])

    return (
        <>
            <div style={{zIndex: "25"}} className={''}>
                {/*help modal*/}
                <button onClick={() => setHelpWindowIsOpen(true)}
                        className={`btn-help btn`}>{t('button.help')}</button>
                <div/>
                {helpWindowIsOpen &&
                    <Modal setIsOpen={setHelpWindowIsOpen} t={t} array={newRefData.current} index={index}
                           setFoundSection={setFoundSection} setFoundRole={setFoundRole}
                    />}
            </div>

            <div className={'container-search space-between'}>
                <div/>
                {/*used for flexbox*/}
                {introStepsCount === 4 ?
                    <div className={'instruction-box-wrapper'}>
                        <div className={'instruction-box'}>
                            <h3>{t('search.instruction')}</h3>
                        </div>
                    </div>
                    : null}
                <div style={{position: "sticky", bottom: "0"}}>
                    {introStepsCount > 3 ?
                        <ScanningVIewNavigation array={newRefData.current} index={index} setIndex={setIndex}
                                                introStepsCount={introStepsCount}/> : null}
                    {introStepsCount > 3 ?
                        <div className={'bottom-sheet'}>
                            {(totalGamificationElements - gamificationArray.length) === totalGamificationElements ?
                                <div>
                                    <p>{t('search.foundAll')}</p>
                                    <img src={party} alt="Party"/>
                                </div>
                                : <>
                                    <div className={''}>
                                        <p>{t('search.gamification')}</p>
                                        <img src={lupe} alt="Lupe"/>
                                    </div>
                                    <p>
                                        <span>{totalGamificationElements - gamificationArray.length}</span>{'/' + totalGamificationElements}
                                    </p></>}


                        </div> : null}
                </div>
            </div>
        </>
    );
};

export default ScanningView;

