import {initializeApp} from "firebase/app";
import {getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    projectId: "klimaball-22ar",
    storageBucket: "klimaball-22ar.appspot.com",
    messagingSenderId: "669049639582",
    appId: "1:669049639582:web:4bb3224cfd53f84ae6218f",
    measurementId: "G-ZLK9S7D90S"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)

export const storage = getStorage(app);

//connectFirestoreEmulator(db, 'localhost', 8080);
