import React, {useContext, useEffect, useRef, useState} from 'react';
import i18next from "i18next";
import {Context} from "../../context/Context";
import {useTranslation} from "react-i18next";
import flag_de from "../../assets/img/de.svg";
import flag_fr from "../../assets/img/fr.svg";
import flag_it from "../../assets/img/it.svg";
import flag_gb from "../../assets/img/gb.svg";
import Popup from "../popup/Popup";
import './LanguageDropdown.css'

const LanguageSwitch = (props) => {
    const dropdownRef = useRef(null);
    const {language, setLanguage} = useContext(Context);
    const {t} = useTranslation();
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (visibility && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setVisibility(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [visibility]);

    const languages = [
        {
            code: 'de',
            name: 'Deutsch',
            country_codes: 'de',
        },
        {
            code: 'fr',
            name: 'Français',
            country_codes: 'fr',
        },
        {
            code: 'it',
            name: 'Italiano',
            country_codes: 'it',
        },
        {
            code: 'en',
            name: 'English',
            country_codes: 'gb'
        }
    ]

    const LanguageFlag = (code) => {
        switch (code) {
            case 'de':
                return <img src={flag_de} alt={"deutsch"} className={`flag-icon`}/>
            case 'fr':
                return <img src={flag_fr} alt={"francais"} className={`flag-icon`}/>
            case 'it':
                return <img src={flag_it} alt={"Italiano"} className={`flag-icon`}/>
            case 'en':
                return <img src={flag_gb} alt={"english"} className={`flag-icon`}/>
            default:
        }
    }
    const popupCloseHandler = (e) => {
        setVisibility(e);
    };

    return (
        <>
            <button className={'btn-help btn'} onClick={() => setVisibility(true)}>{t('home.language')}</button>
            <Popup
                onClose={popupCloseHandler}
                show={visibility}
            >
                <div className={'menu-container'}>
                    {languages.map(({code, name, country_codes }, index) => (
                        <button key={index+'language'} className={'btn btn-white'} onClick={() => {
                            i18next.changeLanguage(code);
                            setLanguage(() => code);
                            console.log(language);
                            setVisibility(false)
                        }}>{LanguageFlag(`${code}`)}
                            <p>{name}</p>
                        </button>
                    ))}
                </div>
            </Popup>
        </>
    );
};

export default LanguageSwitch;
