import React from "react";
import {Link} from "react-router-dom";
import './Modal.css'

/**
 * Modal for help instructions.
 * @param setIsOpen - Open and close help window
 * @param t = Translation variable for i18next
 * @param array = Array with all marker
 * @param index = Array position
 * @param {function} setFoundSection - Set selected Section.
 * @param {function} setFoundRole - Set selected character.
 * @return {JSX.Element}
 * @constructor
 */
const Modal = ({ setIsOpen, t, array, index, setFoundSection, setFoundRole }) => {

    let isRole = false;

    if(array[index].type === 'role'){
        isRole = true;
    }

    return (
        <>
            <div  className={`modal`}>
                <div className="modal-content">
                    <span className="close"  onClick={() => setIsOpen(false)} >&times;</span>
                    <p>{t('modal.help')}</p>
                    <div className={'image-wrapper'}>
                        <img className={'img-modal'} src={array[index].img} alt=""/>
                    </div>
                    <p>{t('modal.description')}</p>
                    <div className={'btn-set-col'}>
                        {isRole ? <Link to={'/about-role'} onClick={() => setFoundRole(() => array[index].marker)}className={`btn-outline-white btn`}>{t('button.skip')}</Link> :
                            <Link to={'/about-section'} onClick={() => setFoundSection(() => array[index].marker)}  className={`btn-outline-white btn`}>{t('button.skip')}</Link> }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
