import {useEffect, useState} from "react";
import "./Popup.css";

const Popup = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <div
            style={{
                visibility: show ? "visible" : "hidden",
                opacity: show ? "1" : "0"
            }}
            className={'overlay'}
        >
            <div className={'popup card-explanation'}>
                <div className={'content'}>{props.children}</div>
            </div>
        </div>
    );
};

export default Popup;
