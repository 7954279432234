/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

/**
 * Box with different text content depending on the current intro step
 * @property {number} introStepsCount – current step of introduction.
 */
const IntroText = ({ introStepsCount }) => {

    const {t} = useTranslation();
    const [introText, setIntroText] = useState("");

    console.log("render Intro text")

    /**
     * Changes introText depending on state of introStepsCount
     */
    useEffect(() => {
        // changeIntroText()
        switch (introStepsCount) {
            case 0:
                setIntroText(t('intro.introText1'));
                break;
            case 1:
                setIntroText(t('intro.introText2'));
                break;
            case 2:
                setIntroText(t('intro.introText3'))
                break;
        }
    }, [introStepsCount])

    return (
        <>
            <div className={'intro-text btn'}>
                <div>
                    <h3>{introText}</h3>
                </div>
            </div>
        </>
    );
};

export default IntroText;

