import React, {useEffect} from 'react'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import asterix1 from '../../assets/img/✺.svg'
import asterix2 from '../../assets/img/asterix2.svg'
import asterix3 from '../../assets/img/asterix3.svg'
import LanguageSwitch from "../../components/languageDropdown/LanguageSwitch";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../firebase/firebaseConfig";

/**
 * Home screen with start button.
 * @param {boolean} showScanIcon - Set visibility of scan icon.
 * @param setGamificationArray
 * @return {JSX.Element}
 * @constructor
 */
const Home = ({showScanIcon, setGamificationArray, setTotalGamificationElements}) => {

    console.log("update home")

    const {t} = useTranslation();

    useEffect(() => {
        const numberOfActionsRef = collection(db, "userInput");

        const getNumberOfActions = async () => {
            const data = await getDocs(numberOfActionsRef);
            return data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        };
        getNumberOfActions().then(res => {
                const result = res.map(obj => obj.num)
                const total = result.length
                setGamificationArray(() => result)
                setTotalGamificationElements(() => total)
            }
        );

    }, [])


    return (
        <>
            <div className="container green container-start z-index">
                <h1>{t('home.title')}</h1>
                <p>{t('home.text')}</p>
                <Link to={'intro'} onClick={() => {
                    showScanIcon.current = true;
                }} className={`btn-start-ar btn`}>{t('button.welcome')}</Link>
                {/*language select*/}
                <div className={'nav-lng'}>
                    <LanguageSwitch/>
                </div>
            </div>
            <div className={'asterix'} id={'asterix-1'}>
                <img src={asterix1} alt="Asterix"/>
            </div>
            <div className={'asterix'} id={'asterix-2'}>
                <img src={asterix2} alt="Asterix"/>
            </div>
            <div className={'asterix'} id={'asterix-3'}>
                <img src={asterix3} alt="Asterix"/>
            </div>
        </>
    )
}

export default Home
