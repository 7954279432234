import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {collection, getDocs, query, Timestamp, orderBy} from "firebase/firestore";
import {db} from "../../firebase/firebaseConfig";
import i18next from "i18next";

const Evaluation = ({fromWebApp}) => {

    const {t} = useTranslation();

    const [locations, setLocations] = useState([]);
    const [evaluation, setEvaluation] = useState([]);
    const [actions, setActions] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [svgIndex, setSvgIndex] = useState(0);
    const [sections, setSections] = useState([])

    useEffect(() => {
        // only for embedding at www.klimaball.ch/auswertung
        if (!fromWebApp) {
            document.querySelector(".App").style.background = '#E0CFE6';
        }

        const locationRef = collection(db, "locations");
        const qLocation = query(locationRef, orderBy('startDate', 'asc'))
        const evaluationRef = collection(db, "userInput");
        const actionRef = collection(db, "actions");

        const getLocations = async () => {
            const data = await getDocs(qLocation);
            return data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        };
        const getEvaluation = async () => {
            const data = await getDocs(evaluationRef);
            return data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        };

        const getActions = async () => {
            const data = await getDocs(actionRef);
            return data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        };

        getLocations().then(res => {
                setLocations(res);
            }
        );
        getEvaluation().then(res => {
                setEvaluation(res)
            }
        );

        getActions().then(res => {
                setActions(res)
                setSections(res.map(a => a.section))
            }
        );


        return () => {
        }

// eslint-disable-next-line
    }, []);

    useEffect(() => {
    }, [activeIndex, locations])

    /*filters rating array, e.g. veryPositive, and extracts ratings between fromDate and toDate*/
    const filterByDate = (array, startDate, toDate) => {
        let filteredArray = array.filter((item: any) => {
            return item.timestamp.toDate() >= startDate.toDate() &&
                item.timestamp.toDate() <= toDate.toDate();
        })
        return filteredArray
    }

    const calculateDataForChart = (el, startDate, toDate) => {

        /*element comes from collection 'roles' instead of 'actions', therefore it returns 'undefined' and cann be ignored*/
        if (el === undefined) {
            return (<p/>)
        }

        let countPositive = 0;
        let countNegative = 0;
        let countVeryPositive = 0;
        let countVeryNegative = 0;

        // to get all votes, there is no end date in the db, therefore we set now (new Date) as toDate
        if (toDate === undefined) {
            toDate = Timestamp.fromDate(new Date()) // firebase firestore function to convert timestamp to date
        }

        //very positive
        const {rating: {veryPositive}} = el;

        if (veryPositive.length >= 1) {
            countVeryPositive = filterByDate(veryPositive, startDate, toDate).length
        }
        //positive
        const {rating: {positive}} = el;
        if (positive.length >= 1) {
            countPositive = filterByDate(positive, startDate, toDate).length;

        }
        //negative
        const {rating: {negative}} = el;
        if (negative.length >= 1) {
            countNegative = filterByDate(negative, startDate, toDate).length;
        }
        //very negative
        const {rating: {veryNegative}} = el;
        if (veryNegative.length >= 1) {
            countVeryNegative = filterByDate(veryNegative, startDate, toDate).length;
        }
        let total = countVeryPositive + countPositive + countNegative + countVeryNegative;

        if (total === 0) {
            return <p>0</p>
        }

        let percentageVeryPositive = countVeryPositive / total * 100;
        let percentagePositive = countPositive / total * 100;
        let percentageNegative = countNegative / total * 100;
        let percentageVeryNegative = countVeryNegative / total * 100;

        return (
            <div className={'stacked-chart-wrapper'}>
                <div style={{width: ' 100%'}}>
                    <div className={'stacked-chart-container'}>
                <span className={'bg-orange'}
                      style={{
                          width: (percentageVeryNegative.toFixed(2)) + '%',
                          visibility: percentageVeryNegative === 0 ? 'hidden' : 'visible'
                      }}>{percentageVeryNegative.toFixed(0) + '%'}</span>
                        <span className={'bg-white'}
                              style={{
                                  width: (percentageNegative.toFixed(2)) + '%',
                                  visibility: percentageNegative === 0 ? 'hidden' : 'visible'
                              }}>{percentageNegative.toFixed(0) + '%'}</span>
                        <span className={'bg-yellow'}
                              style={{
                                  width: (percentagePositive.toFixed(2)) + '%',
                                  visibility: percentagePositive === 0 ? 'hidden' : 'visible'
                              }}>{percentagePositive.toFixed(0) + '%'}</span>
                        <span className={'bg-green'}
                              style={{
                                  width: (percentageVeryPositive.toFixed(2)) + '%',
                                  visibility: percentageVeryPositive === 0 ? 'hidden' : 'visible'
                              }}>{percentageVeryPositive.toFixed(0) + '%'}</span>

                    </div>
                    <div className={'stacked-chart-container'}>
                <span
                    style={{
                        width: (percentageVeryNegative.toFixed(2)) + '%',
                        visibility: percentageVeryNegative === 0 ? 'hidden' : 'visible'
                    }}>{countVeryNegative}
                </span>
                        <span
                            style={{
                                width: (percentageNegative.toFixed(2)) + '%',
                                visibility: percentageNegative === 0 ? 'hidden' : 'visible'
                            }}>{countNegative}
                    </span>
                        <span
                            style={{
                                width: (percentagePositive.toFixed(2)) + '%',
                                visibility: percentagePositive === 0 ? 'hidden' : 'visible'
                            }}>{countPositive}
                    </span>
                        <span
                            style={{
                                width: (percentageVeryPositive.toFixed(2)) + '%',
                                visibility: percentageVeryPositive === 0 ? 'hidden' : 'visible'
                            }}>{countVeryPositive}
                    </span>

                    </div>
                </div>

                <div className={'bar-chart-label hidden-on-mobile'}>
                    <p>{t('evaluation.percentage')}</p>
                    <p>{t('evaluation.replies')}</p>
                </div>
            </div>
        )
    }

    /*connection between evaluation results with action name and num*/
    const returnEvalObject = (number) => {
        let found = evaluation.find(element => element.num === number)
        return found;
    }

    const formatDate = (date) => {
        const yy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        const formattedDate = dd + '.' + mm + '.' + yy.toString().substring(2);

        return formattedDate
    }
    const green = '#005E64';
    const purple = '#D8ADF7';
    // x coordinates
    const firstRow = 180
    const secondRow = 550
    const thirdRow = 900
    //y coordinates
    const firstColumn = 320
    const secondColumn = 550
    const thirdColumn = 780

    //splits text of finances into three rows
    const textFinances = t(`section.${sections[4]}`).toUpperCase();
    const linesFinances = textFinances.split(' '); // split the text into an array of strings
    const tspanFinances = linesFinances.map((line, index) => {
        return <tspan key={index + 'finances'} x={secondRow} textAnchor={'middle'} dy="1em">{line}</tspan>
    });

    //splits text of industry into three rows
    const textIndustry = t(`section.${sections[6]}`).toUpperCase();
    const linesIndustry = textIndustry.split(' '); // split the text into an array of strings
    const tspanIndustry = linesIndustry.map((line, index) => {
        return <tspan key={index + 'industry'} x={firstRow + 30} textAnchor={'middle'} dy="1em">{line}</tspan>
    });

    //splits text of nutrition into three rows
    const textNutrition = t(`section.${sections[5]}`).toUpperCase();
    const linesNutrition = textNutrition.split(' '); // split the text into an array of strings
    const tspanNutrition = linesNutrition.map((line, index) => {
        return <tspan key={index + 'nutrition'} x={secondRow} textAnchor={'middle'} dy="1em"
                      fill={'white'}>{line}</tspan>
    });

    //splits text of adaption into three rows
    const textAdaption = t(`section.${sections[3]}`).toUpperCase();
    const linesAdaption = textAdaption.split(' '); // split the text into an array of strings
    const tspanAdaption = linesAdaption.map((line, index) => {
        return <tspan key={index + 'adaption'} x={secondRow} textAnchor={'middle'} dy="1em">{line}</tspan>
    });

    const strokeWidth = 4;

    return (

        <>
            <div className="container-evaluation ">
                {fromWebApp ? <h1>{t('evaluation.title')}</h1> : null}

                {/*todo Location Filter*/}
                <div className={'location-filter'}>
                    {locations.map((el, i) => {
                        return (
                            <>
                                <p key={'location-key' + i}
                                   className={'uppercase'}
                                   style={{
                                       textDecoration: activeIndex === i ? 'underline' : ''
                                   }}
                                   onClick={() => setActiveIndex(i)}>
                                    {el.location === 'aaaall' ? t('evaluation.all') + ' (21.06.2022-heute)' : el.city + ', ' + el.location + ' (' + formatDate(el.startDate.toDate()) + '-' + formatDate(el.endDate.toDate()) + ')'} {locations.length - 1 === i ? null : `•`}
                                </p>
                            </>
                        )
                            ;
                    })}
                </div>
                {/*Legende*/}
                <div className={'legend-box'}>
                    <p><span className={'bg-orange'}/>{t('doughnut.veryNegative')}</p>
                    <p><span className={'bg-white'}/>{t('doughnut.negative')}</p>
                    <p><span className={'bg-yellow'}/>{t('doughnut.positive')}</p>
                    <p><span className={'bg-green'}/>{t('doughnut.veryPositive')}</p>
                </div>
                <p>{t('evaluation.sentence')} </p>
                <div className={'action-selector'}>
                    {/*click interaction*/}
                    {sections ?
                        <div className={'svg-container'}>
                            <svg id='ball' className={'svg'} viewBox="0 0 1053 1084" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M414.024 53.6617L667.657 53.5879L739.5 195.524L360.179 192.387L414.024 53.6617Z"
                                    fill="#004556"/>
                                <path id={`area3`} onClick={() => setSvgIndex(3)}
                                      d="M328.394 417.874H779.189L739.498 200.173L360.176 196.999L328.394 417.874Z"
                                      fill={svgIndex === 3 ? purple : '#E7F667'} stroke={svgIndex === 3 ? 'white' : ''} strokeWidth={strokeWidth} />
                                <path
                                    d="M841.648 94.4026L673.459 51.2979L744.083 195.632L974.137 237.741L841.648 94.4026Z"
                                    fill="white"/>
                                <path
                                    d="M612.219 12.8076L670.388 47.0921L837.136 89.1267L680.847 26.3886L612.219 12.8076Z"
                                    fill="#004556"/>
                                <path
                                    d="M456.747 13.6195L607.567 12.8076L666.993 49.4171L415.504 48.8636L456.747 13.6195Z"
                                    fill="#004556"/>
                                <path id={'area4'} onClick={() => setSvgIndex(4)} stroke={svgIndex === 4 ? 'white' : ''} strokeWidth={strokeWidth}
                                      d="M327.101 675.397L327.803 422.119L779.19 422.488V674.658L327.101 675.397Z"
                                      fill={svgIndex === 4 ? purple : 'white'}/>
                                <path id={'area5'} onClick={() => setSvgIndex(5)} stroke={svgIndex === 5 ? 'white' : ''} strokeWidth={strokeWidth}
                                      d="M360.842 901.952L329.392 679.675L776.861 680.265L734.768 903.908L360.842 901.952Z"
                                      fill={svgIndex === 5 ? purple : '#EE560A'}/>
                                <path
                                    d="M414.06 1048.87L362.247 908.225L733.586 909.516L666.104 1050.31L414.06 1048.87Z"
                                    fill="#004556"/>
                                <path id={'area0'} onClick={() => setSvgIndex(0)} strokeWidth={strokeWidth} stroke={svgIndex === 0 ? 'white' : ''}
                                      d="M1051.34 440.125L977.685 242.278L743.603 199.653L784.476 417.871L1051.34 440.125Z"
                                      fill={svgIndex === 0 ? purple : '#004556'}/>
                                <path id={'area1'} onClick={() => setSvgIndex(1)} strokeWidth={strokeWidth} stroke={svgIndex === 1 ? 'white' : ''}
                                      d="M784.104 422.119L1053 444.779L1052.44 661.926L784.104 674.917V422.119Z"
                                      fill={svgIndex === 1 ? purple : '#E7F667'}/>
                                <path id={'area2'} onClick={() => setSvgIndex(2)} strokeWidth={strokeWidth} stroke={svgIndex === 2 ? 'white' : ''}
                                      d="M783.625 679.674L1051.52 667.864L975.577 868.885L741.051 903.354L783.625 679.674Z"
                                      fill={svgIndex === 2 ? purple : 'white'}/>

                                <path
                                    d="M673.606 1049.24L741.496 908.597L971.919 874.719L844.789 1014.85L673.606 1049.24Z"
                                    fill="#EE560A"/>
                                <path d="M683.325 1050.31L689.571 1061.97L722.684 1056.14V1041.04" fill="#004556"/>
                                <path d="M690.346 1057.5L692.564 1051.53L689.57 1047.06L690.346 1057.5Z"
                                      fill="#004556"/>
                                <path
                                    d="M699.549 1062.71V1066.84L689.57 1073.63V1080.5C689.57 1080.5 690.679 1083.71 701.359 1083.71C709.305 1083.71 716.142 1082.01 716.142 1082.01V1076.62L705.24 1066.84V1061.97"
                                    fill="#004556"/>
                                <path
                                    d="M366.163 1062.16V1066.29L356.185 1073.08V1079.95C356.185 1079.95 357.293 1083.16 367.974 1083.16C375.919 1083.16 382.756 1081.46 382.756 1081.46V1076.07L371.854 1066.29V1061.42"
                                    fill="#004556"/>
                                <path
                                    d="M825.496 1021.3V1025.43L815.518 1032.23V1039.09C815.518 1039.09 816.626 1042.3 827.307 1042.3C835.252 1042.3 839.761 1040.6 839.761 1040.6L839.65 1034.03L831.15 1025.43V1020.56"
                                    fill="#004556"/>
                                <path
                                    d="M228.352 1020.6V1024.73L218.374 1031.53V1038.39C218.374 1038.39 219.483 1041.6 230.163 1041.6C238.109 1041.6 242.618 1039.9 242.618 1039.9L242.507 1033.33L234.007 1024.73V1019.86"
                                    fill="#004556"/>
                                <path d="M350.863 1041.01V1054.96L352.563 1057.69L387.746 1058.76V1045.22"
                                      fill="#004556"/>
                                <path
                                    d="M449.023 15.1318L370.675 26.3878L228.836 89.3842L409.332 51.2985L449.023 15.1318Z"
                                    fill="#E7F667"/>
                                <path
                                    d="M409.332 54.6191L220.928 94.3656L99.5996 234.493L356.152 193.233L409.332 54.6191Z"
                                    fill="#004556"/>
                                <path id={'area6'} onClick={() => setSvgIndex(8)} strokeWidth={strokeWidth} stroke={svgIndex === 8 ? 'white' : ''}
                                      d="M99.4508 240.028L354.155 197.477L321.264 417.872L30.8965 437.985L99.4508 240.028Z"
                                      fill={svgIndex === 8 ? purple : '#EE560A'}/>
                                <path id={'area7'} onClick={() => setSvgIndex(7)} strokeWidth={strokeWidth} stroke={svgIndex === 7 ? 'white' : ''}
                                      d="M29.2314 444.591L322.334 423.002L322.001 674.36L30.8575 661.96L29.2314 444.591Z"
                                      fill={svgIndex === 7 ? purple : '#004556'}/>
                                <path id={'area8'} onClick={() => setSvgIndex(6)} strokeWidth={strokeWidth} stroke={svgIndex === 6 ? 'white' : ''}
                                      d="M31.2666 667.903L100.967 864.015L352.973 901.4L322.336 679.676L31.2666 667.903Z"
                                      fill={svgIndex === 6 ? purple : '#E7F667'}/>
                                <path
                                    d="M101.41 871.062L355.301 908.225L407.854 1048.35L218.304 1009.09L101.41 871.062Z"
                                    fill="#004556"/>
                                <path d="M205.294 998.126V1010.56L219.19 1019.86H239.257V1014.92" fill="#004556"/>
                                <path
                                    d="M64.9348 825.78L95.2391 864.936L26.2413 668.714L2.18262 656.498L64.9348 825.78Z"
                                    fill="#004556"/>
                                <path d="M0 465.552L1.21957 650.851L25.537 663.288L24.613 443.188L0 465.552Z"
                                      fill="#004556"/>
                                <path
                                    d="M64.1544 288.081L1.62402 460.906L25.5349 437.988L91.8349 245.898L64.1544 288.081Z"
                                    fill="#004556"/>
                                <path
                                    d="M455.454 10.8505L465.506 11.9207L476.261 7.82427L494.111 10.8505V6.34808L474.117 1.8457L455.454 10.8505Z"
                                    fill="#004556"/>
                                <path
                                    d="M584.95 0L559.782 8.85714H573.567L588.202 6.86429L599.584 9.44762L609.895 9.66905L595.297 1.84524L584.95 0Z"
                                    fill="#004556"/>
                                {/*klimaanpassung*/}
                                <text x={secondRow} y={firstColumn - 55} fill={green} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(3)}
                                      style={{fontSize: "32px"}}>{tspanAdaption}</text>
                                {/*finanzen*/}
                                <text x={secondRow} y={secondColumn - 55} fill={green} onClick={() => setSvgIndex(4)}
                                      style={{fontSize: "32px"}}>{tspanFinances}</text>
                                {/*nutrition*/}
                                <text x={secondRow} y={thirdColumn - 55} fill={green} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(5)}
                                      style={{fontSize: "32px"}}>{tspanNutrition} </text>
                                {/*energie*/}
                                <text x={thirdRow} y={firstColumn} fill={'white'} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(0)}
                                      style={{fontSize: "32px"}}>{t(`section.${sections[0]}`).toUpperCase()}</text>
                                {/*mobility*/}
                                <text x={thirdRow + 22} y={secondColumn} fill={green} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(1)}
                                      style={{fontSize: "32px"}}>{t(`section.${sections[1]}`).toUpperCase()}</text>
                                {/*konsum*/}
                                <text x={thirdRow} y={thirdColumn} fill={green} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(2)}
                                      style={{fontSize: "32px"}}>{t(`section.${sections[2]}`).toUpperCase()}</text>
                                {/*industry*/}
                                <text x={firstRow} y={thirdColumn - 55} fill={green} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(6)}
                                      style={{fontSize: "32px"}}>{tspanIndustry}</text>
                                {/*bio*/}
                                <text x={firstRow} y={secondColumn} fill={'white'} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(7)}
                                      style={{fontSize: "32px"}}>{t(`section.${sections[7]}`).toUpperCase()}</text>
                                {/*justice*/}
                                <text x={firstRow + 26} y={firstColumn} fill={'white'} textAnchor={'middle'}
                                      onClick={() => setSvgIndex(8)}
                                      style={{fontSize: "32px"}}>{t(`section.${sections[8]}`).toUpperCase()}</text>
                            </svg>
                        </div> : <p>loading...</p>}
                    {/*Evaluation of the actions per section*/}
                    {actions?.map((el, i) => {
                        if (svgIndex === i) {
                            return (
                                <div className={'section-evaluation'} key={i + 'sectionEval'}>
                                    <h3 className={'bold'}>{el[i18next.language].name}</h3> {/*section name*/}
                                    {el[i18next.language].actions.map((child, i) => {
                                        return (
                                            <>
                                                <p key={i + 'actionsEval'}>{child.description}</p>

                                                {calculateDataForChart(returnEvalObject(child.num), locations[activeIndex].startDate, locations[activeIndex].endDate)}
                                                <br/>
                                            </>
                                        )
                                    })

                                    }
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </>
    )
        ;
};

export default Evaluation;
