/* eslint-disable */
import React, { useEffect} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import IntroText from "../../components/arElements/IntroText";
import klimaball from "../..//assets/img/Klimaball.png"

/**
 * This view contains some introductions to make users understand the functionality and content of this application.
 * @param {function} setIntroStepsCount - Set current intro step.
 * @param {number} introStepsCount - The current intro step.
 * @param {boolean} showScanIcon - Set visibility of scan icon.
 * @param {function} setStarted - Make AR Overlay visible.
 * @return {JSX.Element}
 * @constructor
 */
const IntroView = ({setIntroStepsCount, introStepsCount, showScanIcon, setStarted}) => {

    const {t} = useTranslation();
    let count = 0;

    console.log("render intro view")

    /**
     * Show AR-Overlay without scan icon and handle listeners to skip first step on 'targetFound'
     */
    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'transparent';
        console.log("on mount intro")

        setStarted(true); // show AR View
        showScanIcon.current = false; // disable scan icon

        // skip first step when intro target is found
        const objectIntro = document.querySelector('#target-intro');
        objectIntro.addEventListener('targetFound', countSteps);

        return () => {
            // remove event listener after intro
            objectIntro.removeEventListener('targetFound', countSteps);
        }
    }, []);

    /**
     * Increase step by one and set state.
     */
    const countSteps = () => {
        count = introStepsCount + 1;
        setIntroStepsCount(count);
    }

    /**
     * Add button depending on step.
     */
    useEffect(() => {
        buttonStart();
    }, [introStepsCount])

    /**
     * Change Button depending on current intro step.
     * @return {JSX.Element}
     */
    const buttonStart = () => {
        if (introStepsCount < 2) {
            return <button className={`btn btn-white`}
                           onClick={() => countSteps()}>{t('button.forward')}</button>
        } else {
            return <Link to={'/search'} onClick={() => {
                                                        showScanIcon.current = true;
                                                        countSteps();
            }} className={`btn-white btn`}>{t('button.startApp')}</Link>
        }
    }

    return (
        <>
            <div className={'container intro space-between'}>
                <div className={'klimaball'}>
                    <img className={'klimaball-img'} src={klimaball} alt="Klimaball"/>
                </div>
                <IntroText introStepsCount={introStepsCount}/>
                <div className={'btn-set btn-set-right'}>
                    {buttonStart()}
                </div>
            </div>
        </>
    );
};

export default IntroView;

