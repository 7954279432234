import React, {useEffect} from 'react';
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../firebase/firebaseConfig";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import carla from "../../assets/roles/carla.png"
import urs from "../../assets/roles/urs.png"
import martin from "../../assets/roles/martin.png"
import sabine from "../../assets/roles/sabine.png"
import RoleImages from "../../components/RoleImages";

/**
 * This view contains of information about the selected role.
 * @param {html} saveARSystem - The AR System.
 * @param {useRef} showScanIcon - Set visibility of scan icon.
 * @param {string} foundRole - The selected role.
 * @param {[]} activeRole - Contains all params of current role.
 * @param {function} setActiveRole - Set params of current role.
 * @return {JSX.Element}
 * @constructor
 */
const AboutRole = ({saveARSystem, showScanIcon, foundRole, activeRole, setActiveRole}) => {
    const {t} = useTranslation();

    useEffect(() => {
    }, [activeRole])

    /**
     * Request data from selected role from database and make scanning icon invisible.
     */
    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'var(--yellow)';

        // enable scanning icon
        const overlay = document.querySelectorAll('.mindar-ui-overlay');
        overlay.forEach(i => {
            i.style.display = 'none';
        });

        // get role according to found marker
        const roleRef = collection(db, "roles");
        const qRole = query(roleRef, where("name", "==", foundRole));

        const getRole = async () => {
            const data = await getDocs(qRole);
            setActiveRole(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        };
        getRole();
    }, []);

    let img
    switch (foundRole) {
        case "Carla Rossi":
            img = carla;
            break;
        case "Urs Schneider" :
            img = urs;
            break;
        case "Martin Leroy" :
            img = martin;
            break;
        case "Sabine Müller" :
            img = sabine;
            break;
        default:
    }

    return (
        <div className={'space-between vh-100 bg-yellow'}>
            <div className={'img-role right img-role-start'}>
                <RoleImages foundRole={foundRole}/>
            </div>
            {activeRole.map((role, key) => {
                return (
                    <div key={key}>
                        <div  className={'card-explanation relative hello'}>
                            <h1>{role[i18next.language].hello}</h1>
                        </div>
                        <div className={'card-explanation relative '}>
                            <p>{role[i18next.language].description}</p>
                        </div>
                    </div>
                )
            })}

            <div className={'btn-set'}>
                <Link to={'/search'} onClick={() => {showScanIcon.current = true; saveARSystem.unpause();}} className={'btn btn-outline-green'}>{t('button.start')} </Link>
                <Link to={'/about-role/role-rating'} className={'btn btn-purple-green'}>{t('button.forward')} </Link>
            </div>
        </div>

    );
};

export default AboutRole;
