import React, { useEffect} from 'react';
import Rating from "../../components/Rating";
import i18next from "i18next";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RoleImages from "../../components/RoleImages";

/**
 *
 * @param rating
 * @param setRating
 * @param {[]} activeRole - Contains all params of current role.
 * @param setSelectedAction
 * @param {[]} selectedAction - Contains all params of current action.
 * @param foundRole
 * @param questionArray
 * @param setQuestionArray
 * @return {JSX.Element}
 * @constructor
 */
const RatingRole = ({rating, setRating, activeRole, setSelectedAction, selectedAction, foundRole, questionArray, setQuestionArray, setGamificationArray, gamificationArray}) => {

    const {t} = useTranslation();

    useEffect(() => {
        let count

        if( questionArray.length === 0){
            //gets all questions for role play
            const {0: {[i18next.language]: {qAndA}}} = activeRole;
            console.log('qAndA -RR', qAndA);

            // save question to separate array
            setQuestionArray(qAndA);

            // selects first question of array
            count = qAndA[0];
            console.log("count if", count)

        } else{
            // selects random question from question array
            console.log('questionArray -RR', questionArray);
            if(questionArray.length > 2){
                count = questionArray[0]
            }else{
                count = questionArray[Math.floor(Math.random() * questionArray.length)];
            }
        }

        setSelectedAction(selectedAction => ({
            ...selectedAction,
            ...count
        }));

    }, [])

    useEffect(() => {
        console.log("test update")
    }, [activeRole, questionArray, selectedAction]);

    return (
        <>
            <div className={'container space-between bg-yellow vh-100'}>
                <div>
                    <div className={'img-role'}>
                        <RoleImages foundRole={foundRole}/>
                    </div>
                    <div className={'card-explanation relative'}>
                        <p>{selectedAction.question}</p>
                    </div>
                </div>
                <Rating questionArray={questionArray} rating={rating} setRating={setRating} selectedAction={selectedAction} fromRole={true} setGamificationArray={setGamificationArray} gamificationArray={gamificationArray}/>
                <div className={'btn-set'}>
                    <Link to={'/about-role'} className={'btn btn-outline-green'}>{t('button.back')}</Link>
                </div>
            </div>
        </>
    );
};

export default RatingRole;
