import React, {useEffect, useState, useRef} from 'react';
import {doc, updateDoc, collection, query, where, getDocs, arrayUnion} from "firebase/firestore";
import {db} from "../firebase/firebaseConfig";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Popup from "./popup/Popup";

/**
 * This component contains of rating all options and user input field.
 * @param questionArray - Array with all questions of a role
 * @param {Boolean} fromRole - Used in role flow
 * @param {Boolean} fromAction - Used in action flow
 * @param {[]} selectedAction - Contains all params of current action.
 * @param rating - Store "userInput" document id, then store selected rating
 * @param setRating - Set rating value
 * @return {JSX.Element}
 * @constructor
 */
const Rating = ({
                    questionArray,
                    fromRole,
                    fromAction,
                    selectedAction,
                    rating,
                    setRating,
                    setGamificationArray,
                    gamificationArray
                }) => {

    const userInputTextRef = useRef('');
    const [selectedReply, setSelectedReply] = useState('');

    const {t} = useTranslation();
    let navigate = useNavigate();

    const routeChangeAction = () => {
        navigate('/end-screen');
    }

    const routeChangeRole = () => {
        navigate('/rating-explanation');
    }

    /**
     * In an action is selected, get the related document id from Firestore "userInput" collection and map as rating
     */
    useEffect(() => {
        const userInputRef = collection(db, "userInput");

        if (selectedAction !== undefined && selectedAction.length !== 0) {
            const qUserInput = query(userInputRef, where("num", "==", selectedAction.num));

            const getUserInputId = async () => {
                const data = await getDocs(qUserInput);
                setRating(data.docs.map((doc) => ({id: doc.id})));
            };
            getUserInputId()
        }
    }, [selectedAction])

    const answerRef = useRef('');

    /**
     * @param {{t(String)}} label - rating text - translation
     * @param val - rating skala (veryPositive, positive, negative, veryNegative)
     * @return {JSX.Element}
     * @constructor
     */
    const Badge = (label, val) => {

        let reply = val

        return (
            <p className={'btn btn-rating'}
               onClick={(val) => {
                   setSelectedReply(() => reply)
                   setVisibility(!visibility)
               }}>{label}</p>
        )

    }

    /**
     * Decide which page is displayed next.
     * If in role flow, delete current question of question array
     */
    const navigateToNextPage = () => {
        if (fromAction) {
            routeChangeAction();
        }
        if (fromRole) {
            sliceArray()
            routeChangeRole()
        }
    }

    /**
     * Stores result from rating and text are in Firestore document
     * @param e - Event prevents from reloading page after submit
     * @return {Promise<void>}
     */
    const updateRating = async (e) => {
        e.preventDefault();

        // remove found item from gamification array
        setGamificationArray(gamificationArray => gamificationArray.filter( e => e !== selectedAction.num));

        const newInputValue = userInputTextRef.current.value;

        // for db call
        const userInputDoc = doc(db, 'userInput', rating[0].id);
        try{
            if (userInputTextRef.current.value !== '') {
                await updateDoc(userInputDoc, {
                    [`rating.${selectedReply}`]: arrayUnion({
                        timestamp: new Date(),
                        text: newInputValue,
                    })
                }).then(
                    setRating(() => selectedReply) // set rating value for badge on next screen
                );
            }
            if (userInputTextRef.current.value === '') {
                await updateDoc(userInputDoc, {
                    [`rating.${selectedReply}`]: arrayUnion({timestamp: new Date()})
                }).then(
                    setRating(() => selectedReply) // set rating value for badge on next screen
                );
            }
        } catch (e){
            console.error(e)
        }

    }

    /**
     * Deletes current array position of question array to avoid repeating questions
     */
    const sliceArray = () => {
        // find index of question with num of selected question
        const indexOfObject = questionArray.findIndex(object => {
            return object.num === selectedAction.num;
        })
        questionArray.splice(indexOfObject, 1); // remove item at position itemOfObject
    }

    const [visibility, setVisibility] = useState(false);

    /**
     * Close popup
     * @param e - Event
     */
    const popupCloseHandler = (e) => {
        setVisibility(e);
    };

    return (
        <>
            <div className={'badges'}>
                {Badge(t('rating.veryPositive'), 'veryPositive')}
                {Badge(t('rating.positive'), 'positive')}
                {Badge(t('rating.negative'), 'negative')}
                {Badge(t('rating.veryNegative'), 'veryNegative')}
            </div>
            <Popup
                onClose={popupCloseHandler}
                show={visibility}
            >
                <form>
                    <p>{t('rating.question')}</p>
                    <textarea type="text" placeholder={t('evaluation.arguments')} className={'text-input-field'}
                              rows="10"
                              ref={userInputTextRef}/>
                    <div className={'btn-set-popup'}>
                        {fromAction === true ? <Link to={'/end-screen'}
                                                     onClick={(e) => updateRating(e, answerRef.current.value).then(navigateToNextPage)}
                                                     className={'btn yellow btn-underline btn-popup'}>{t('rating.btn-skip')}</Link> : null}

                        {fromRole === true ? <Link to={'/rating-explanation'}
                                                   onClick={(e) => updateRating(e, answerRef.current.value).then(navigateToNextPage)}
                                                   className={'btn green btn-underline btn-popup'}>{t('rating.btn-skip')}</Link> : null}
                        <button className={'btn btn-rating btn-rating-green btn-popup'}
                                onClick={(e) => updateRating(e, answerRef.current.value).then(navigateToNextPage)}>{t('rating.btn-send')}
                        </button>

                    </div>
                </form>
            </Popup>
        </>
    );
};

export default Rating;
