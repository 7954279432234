import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import asterix1 from "../../assets/img/✺.svg";
import asterix2 from "../../assets/img/asterix2.svg";
import asterix3 from "../../assets/img/asterix3.svg";

const NotFound = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className={'not-found container-start z-index container'}>
                <h2>Page not found</h2>
                <div className={'btn-set-col'}>
                    <Link to={'/search'} className={'btn btn-start-ar'}>{t('button.start')} </Link>
                </div>
            </div>
            <div className={'asterix'} id={'asterix-1'}>
                <img src={asterix1} alt="Asterix"/>
            </div>
            <div className={'asterix'} id={'asterix-2'}>
                <img src={asterix2} alt="Asterix"/>
            </div>
            <div className={'asterix'} id={'asterix-3'}>
                <img src={asterix3} alt="Asterix"/>
            </div>
        </>
    );
};

export default NotFound;
