import React, { useEffect} from 'react';
import Rating from "../../components/Rating";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

/**
 * This view shows the selected action and rating options.
 * @param {[]} selectedAction - Contains all params of current action.
 * @param {string} section - The name of current section.
 * @param {string} rating - Pass prop from parent to child
 * @param {function} setRating - Pass prop from parent to child
 * @return {JSX.Element}
 * @constructor
 */
const AboutAction = ({selectedAction, section, rating, setRating, setGamificationArray, gamificationArray}) => {

    const {t} = useTranslation();

    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'var(--green)';
    }, []);

    return (
        <>
            <div className={'container bg-green space-between'}>
                <div>
                    <h1>{section[0][i18next.language].name}</h1>
                    { selectedAction.title === "" ? <h3/> : <h3> → {selectedAction.title}</h3>}
                    <p>{selectedAction.description}</p>
                </div>

                <Rating selectedAction={selectedAction} rating={rating} setRating={setRating} fromAction={true} setGamificationArray={setGamificationArray} gamificationArray={gamificationArray}/>
                <div className={'btn-set'}>
                    <Link to={'/about-section'} className={'btn btn-outline-white'}>{t('button.back')}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default AboutAction;
