import React, {useEffect} from 'react';
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from '../../firebase/firebaseConfig'
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {Link} from "react-router-dom";

/**
 * Shows the section overview.
 * @param {useRef} showScanIcon - Set visibility of scan icon.
 * @param {html} saveARSystem - The AR System.
 * @param {string} foundSection - The selected section.
 * @param {function} setSection - Set the current section.
 * @param {string} section - The name of current section.
 * @param {function} setSelectedAction - Set the selected action.
 * @return {JSX.Element}
 * @constructor
 */
const AboutSection = ({ showScanIcon, saveARSystem, foundSection, setSection, section, setSelectedAction}) => {

    console.log('render about section')

    const {t} = useTranslation();

    /**
     * Request data from selected section from database and make scanning icon invisible.
     */
    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'var(--green)';

        //remove overlay from search component
        const overlay = document.querySelectorAll('.mindar-ui-overlay');
        overlay.forEach(i => {
            i.style.display = 'none';
        });

        const sectionRef = collection(db, "actions");
        const qSection = query(sectionRef, where("section", "==", foundSection));

        const getSection = async () => {
            const data = await getDocs(qSection);
            return data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        };
        getSection().then(res => {
                setSection(res)
            }
        );
        return () => {
            console.log("destroy section")
        }
// eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={'container bg-green space-between'} >
                {section?.map((item, i) => {
                        return (
                            <div key={item.id}>
                                <div>
                                    <h1>{item[i18next.language].name}  </h1>
                                    <p>{item[i18next.language].description} </p>
                                </div>
                                <div className={'wrapper-label'} >
                                    {item[i18next.language].actions.map((el, key) => {
                                        return (
                                            <Link to={`about-action`} onClick={() => setSelectedAction(el, key)} key={'action'+key}
                                                  className={'btn btn-purple'}>{el.title ? el.title : t('handleAction.action') + ' '+ (key + 1)}</Link>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }
                )
                }
                <div className={'btn-set'}>
                    <Link to={'/search'} onClick={() => {showScanIcon.current = true; saveARSystem.unpause();}} className={'btn btn-outline-white'}>{t('button.scan')} </Link>
                </div>
            </div>

        </>
    );
};

export default AboutSection;
