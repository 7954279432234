import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './core/i18next'; /*language switch on website*/
import './assets/fonts/LabilGrotesk/LabilGrotesk-Regular.woff';
import './assets/fonts/LabilGrotesk/LabilGrotesk-Medium.woff';

/*shown while loading page*/
const loadingMarkup = (
    <div className="loading">
        <h3>Loading..</h3>
    </div>
)

ReactDOM.render(
    <Suspense fallback={loadingMarkup}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
