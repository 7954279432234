import React, { useRef, useState, useEffect} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import {Context} from './context/Context'
import Home from './pages/home/Home'
import ScanningView from "./pages/arPages/ScanningView";
import IntroView from "./pages/arPages/IntroView";
import AboutAction from "./pages/actionPages/AboutAction";
import EndScreen from "./pages/actionPages/EndScreenAction";
import AboutSection from "./pages/actionPages/AboutSection";
import AboutRole from "./pages/rolePages/AboutRole";
import RoleRating from "./pages/rolePages/RatingRole";
import RoleRatingExplanation from "./pages/rolePages/EndScreenRole";
import NotFound from "./pages/notFound/NotFound";

// import LogRocket from 'logrocket';
import i18next from "i18next";

import loadable from "@loadable/component";
import EvaluationWebApp from "./pages/evaluation/EvaluationWebApp";
import EvaluationWebsite from "./pages/evaluation/EvaluationWebsite";
const OtherComponent = loadable(() => import('./pages/arPages/MindAROverlay'))

function App() {
    const [saveArSystem, setSaveArSystem] = useState(null)
    const showScanIcon = useRef(false);
    const [index, setIndex] = useState(0);
    const [isScanning, setIsScanning] = useState();
    const [introStepsCount, setIntroStepsCount] = useState(0);
    const [started, setStarted] = useState(false);
    const [foundSection, setFoundSection] = useState('');
    const [foundRole, setFoundRole] = useState('');
    const [section, setSection] = useState();
    const [selectedAction, setSelectedAction] = useState([]);
    const [activeRole, setActiveRole] = useState([]);
    const [rating, setRating] = useState(''); // to identify which userInput to rate
    const [language, setLanguage] = useState(i18next.language);
    // const [theSection, setTheSection] = useState();
    const [questionArray, setQuestionArray] = useState([]) // questions for role play
    const [gamificationArray, setGamificationArray] = useState([]) // array containing not yet evaluated actions
    const [totalGamificationElements, setTotalGamificationElements] = useState(0) // array containing not yet evaluated actions
    const newRefData = useRef(null);

    // LogRocket.init('igfhob/klimaball');

    //language switch
     useEffect(() => {
     }, [language]);

    return (
        <div className="App">
            <Context.Provider value={{
                language, setLanguage, // language switch
            }}>
                <Router>
                    <div className="example-container" style={{visibility: started ? 'visible' : 'hidden'}}>
                    <OtherComponent setIntroStepsCount={setIntroStepsCount} introStepsCount={introStepsCount} showScanIcon={showScanIcon} setSaveArSystem={setSaveArSystem} started={started} setFoundSection={setFoundSection} setFoundRole={setFoundRole}/>
                    </div>
                    <Routes>
                        <Route path="/" element={<Home setGamificationArray={setGamificationArray} setTotalGamificationElements={setTotalGamificationElements} showScanIcon={showScanIcon} setStarted={setStarted} isScanning={isScanning} setIsScanning={setIsScanning}/>} exact/>
                        <Route path="*" element={<NotFound/>} exact/>
                        <Route path="/search" element={saveArSystem === null ? <Navigate to="/" /> :  <ScanningView index={index} setQuestionArray={setQuestionArray}  totalGamificationElements={totalGamificationElements} gamificationArray={gamificationArray} setIndex={setIndex} setIntroStepsCount={setIntroStepsCount} introStepsCount={introStepsCount} setFoundSection={setFoundSection} setFoundRole={setFoundRole} newRefData={newRefData} showScanIcon={showScanIcon} started={started} saveARSystem={saveArSystem}/>} exact/>
                        <Route path="/intro" element={saveArSystem === null ? <Navigate to="/" /> : <IntroView setIntroStepsCount={setIntroStepsCount} introStepsCount={introStepsCount} showScanIcon={showScanIcon} setStarted={setStarted}/>} exact/>
                        <Route path="/about-section/about-action" element={<AboutAction section={section} gamificationArray={gamificationArray} setGamificationArray={setGamificationArray} selectedAction={selectedAction} rating={rating} setRating={setRating}/>} exact/>
                        <Route path="/about-section" element={saveArSystem === null ? <Navigate to="/" /> : <AboutSection showScanIcon={showScanIcon} saveARSystem={saveArSystem} foundRole={foundRole} foundSection={foundSection} section={section} setSection={setSection} setSelectedAction={setSelectedAction}/>} exact/>
                        <Route path="/end-screen" element={<EndScreen selectedAction={selectedAction} showScanIcon={showScanIcon} saveARSystem={saveArSystem} rating={rating}/>} exact/>
                        <Route path="/about-role" element={saveArSystem === null ? <Navigate to="/" /> : <AboutRole saveARSystem={saveArSystem} showScanIcon={showScanIcon} setIsScanning={setIsScanning} foundRole={foundRole} foundSection={foundSection} activeRole={activeRole} setActiveRole={setActiveRole}/>} exact/>
                        <Route path="/about-role/role-rating" element={<RoleRating gamificationArray={gamificationArray} setGamificationArray={setGamificationArray} rating={rating} setRating={setRating} activeRole={activeRole} foundRole={foundRole} questionArray={questionArray} setQuestionArray={setQuestionArray} selectedAction={selectedAction} setSelectedAction={setSelectedAction}/>} exact/>
                        <Route path="/rating-explanation" element={<RoleRatingExplanation showScanIcon={showScanIcon} saveARSystem={saveArSystem} rating={rating} selectedAction={selectedAction} questionArray={questionArray} activeRole={activeRole}/>} exact/>
                        <Route path="/evaluation" element={<EvaluationWebsite/>}/>
                        <Route path='/evaluation-flow' element={saveArSystem === null ? <Navigate to="/" /> :<EvaluationWebApp  showScanIcon={showScanIcon} saveARSystem={saveArSystem}/>}/>
                    </Routes>
                </Router>
            </Context.Provider>
        </div>
    );
}

export default App;
