import React from 'react';

/**
 *
 * @param t - Translation variable to use i18next
 * @return {JSX.Element}
 * @constructor
 */
const FurtherInformationButton = ({t}) => {
    return (
        <div className={'center'}>
            <p>{t('endScreen.further') } &nbsp;
                <a href={'https://klimaball.ch'} rel="noreferrer"
                   target="_blank" className={'purple'}>
                    www.klimaball.ch
                </a>
            </p>
        </div>
    );
};

export default FurtherInformationButton;
