import React, {useEffect, useState} from 'react';
import {Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../firebase/firebaseConfig";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useTranslation} from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

/**
 * Doughnut Chart to display voting results.
 * @param {boolean} fromRole - Set true if DoughnutChart is used in role flow - for using a different color set
 * @param {String} num - action number for DB query
 * @return {JSX.Element}
 * @constructor
 */
const DoughnutChart = ({ fromRole, num}) => {

    const [active, setActive] = useState(false);
    const [userInput, setUserInput] = useState([]);
    const [result, setResult] = useState([]);
    const {t} = useTranslation();

    let countPositive = 0;
    let countNegative = 0;
    let countVeryPositive = 0;
    let countVeryNegative = 0;

    /**
     * Get document from "userInput" Collection of Firestore with given num
     * map result query
     * setActive(true) to show data has arrived
     *
     */
    useEffect(() => {

        const inputRef = collection(db, "userInput");
        const qInput = query(inputRef, where("num", "==", num));

        const getInput = async () => {
            const data = await getDocs(qInput);
            return data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        };
        getInput().then(res => {
                setUserInput(res)
                setActive(true)
            }
        );
    }, [])

    /**
     * Count userInput entries for creating chart.
     */
    useEffect(() => {
        if (setActive && userInput.length > 0) {
            //very positive
            const {0: {rating: {veryPositive}}} = userInput;
            if (veryPositive.length >= 1) {
                countVeryPositive = veryPositive.length;
                // console.log('vp', countVeryPositive)
            }
            //positive
            const {0: {rating: {positive}}} = userInput;
            if (positive.length >= 1) {
                countPositive = positive.length;
                // console.log('p', countPositive)
            }
            //negative
            const {0: {rating: {negative}}} = userInput;
            if (negative.length >= 1) {
                countNegative = negative.length;
                // console.log('n', countNegative)
            }
            //very negative
            const {0: {rating: {veryNegative}}} = userInput;
            if (veryNegative.length >= 1) {
                countVeryNegative = veryNegative.length;
                // console.log('vn', countVeryNegative)
            }
        }

        let total = countVeryPositive + countPositive + countNegative + countVeryNegative;

        countVeryPositive = countVeryPositive / total * 100;
        countPositive = countPositive / total * 100;
        countNegative = countNegative / total * 100;
        countVeryNegative = countVeryNegative / total * 100;

        // save parameters for doughnut chart
        setResult(() => [countVeryPositive, countPositive, countNegative, countVeryNegative])

    }, [userInput])

    let green = '#005E64'
    let white = 'white';
    let yellow = '#E8F666'
    let colorBackground = '';
    let colorFont = '';
    let colorLabel = '';
    if (fromRole) {
        colorBackground = green
        colorFont = green
        colorLabel = [white, white, green, white]

    } else {
        colorBackground = yellow
        colorFont = white
        colorLabel = green

    }

    //doughnut options
    const options = {
        aspectRatio: 2,
        resize: false,
        elements: {},
        layout: {
            padding: {},
        },
        plugins: {
            datalabels: {
                formatter: (value) => {
                    if (value < 5) return '';
                    return Math.round(value) + '%';
                },
                //color: colorLabel,
                color: colorLabel,
                font: {
                    size: '16px',
                    family: "Labil Grotesk",

}
            },
            legend: {
                position: "right",
                labels: {
                    color: colorFont,
                    font: {
                        size: 12,
                        family: "Labil Grotesk",
                    }
                }
            },
            tooltip: {}
        }
    }

    //doughnut data
    const data = {
        labels: [t('doughnut.veryPositive'), t('doughnut.positive'), t('doughnut.negative'), t('doughnut.veryNegative')],
        datasets: [

            {
                label: '# of Votes',
                data: result,
                backgroundColor: [
                    colorBackground,
                    '#D8ADF7',
                    white,
                    '#EF5502',
                ],
                borderColor: [
                    colorBackground,
                    '#D8ADF7',
                    white,
                    '#EF5502',
                ],
                font: {
                    size: '12px'
                }
            },
        ],
        radius: '80%',
    }

    return (
        <>
            {active ? <Doughnut data={data} options={options}/> : <></>}
        </>

    );
};

export default DoughnutChart;
