import React from 'react';
import carla from "../assets/roles/carla.png";
import urs from "../assets/roles/urs.png";
import martin from "../assets/roles/martin.png";
import sabine from "../assets/roles/sabine.png";

/**
 * Display image of selected role.
 * @param {string} foundRole - The selected role.
 * @return {JSX.Element}
 * @constructor
 */
const RoleImages = ({foundRole}) => {

    let img
    switch (foundRole) {
        case "Carla Rossi":
            img = carla;
            break;
        case "Urs Schneider" :
            img = urs;
            break;
        case "Martin Leroy" :
            img = martin;
            break;
        case "Sabine Müller" :
            img = sabine;
            break;
        default:
    }

    return (
        <>
            <img src={img} alt={foundRole}/>
        </>
    );
};

export default RoleImages;
