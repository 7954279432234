import React, {useEffect} from 'react';
import Evaluation from "./Evaluation";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const EvaluationWebApp = ({ showScanIcon, saveARSystem}) => {

    const {t} = useTranslation();

    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'var(--purple)';
    });

    return (
        <>
            <Link to={'/search'} onClick={() => {
                showScanIcon.current = true;
                saveARSystem.unpause()
            }} className={'btn btn btn-outline-white-no-border btn-evaluation'}>{t('button.start')} </Link>
            <Evaluation fromWebApp={true}/>
        </>
    );
};

export default EvaluationWebApp;
