import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";

/**
 * Navigation bar in scanningView.
 * @param array - Shuffeled array of all markers
 * @param index - Array position
 * @param setIndex - Set array position
 * @param {number} introStepsCount - The current intro step.
 * @return {JSX.Element}
 * @constructor
 */
const ScanningVIewNavigation = ({array, index, setIndex, introStepsCount}) => {

    const {t} = useTranslation();

    //todo
    /**
     *
     */
    useEffect(() => {
        if (introStepsCount > 3) {
            nextEl()
        }
    }, [])

    /**
     * Change the position of array to next element.
     * If the position is at the end of the array, go to the beginning
     */
    const nextEl = () => {
        if (index === array.length - 1) {
            setIndex(0);
        } else {
            setIndex(index => index + 1)
        }
    }

    /**
     * Change the position of array to previous element.
     * If the position is at the end of the array, go to the beginning
     */
    const previousEl = () => {
        if (index === 0) {
            setIndex(index => array.length - 1);
        } else {
            setIndex(index => index - 1)
        }
    }

    /**
     * Object to search for in AR
     */
    const objToFind = () => {
        if ('name' in array[index]) {
            let value = array[index].name
            return value
        } else {
            return t(`${array[index].json}`)
        }
    }

    return (
        <>
            <div className={'navigation btn'}>
                <span className={'arrow'} onClick={() => previousEl()}><h2>←</h2></span>
                <div>
                    <h3>{objToFind()}</h3>
                </div>
                {/*<button className={'arrow'} onClick={() => nextEl()}>→</button>*/}
                <span className={'arrow'} onClick={() => nextEl()}><h2>→</h2></span>
            </div>
        </>
    );
};

export default ScanningVIewNavigation;
