import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import FurtherInformationButton from "../../components/buttons/FurtherInformationButton";

import loadable from "@loadable/component";
const DoughnutChart = loadable(() => import("../../components/charts/DoughnutChart"));

/**
 * Final view of action flow. Contains of diagram and multiple navigation options.
 * @param {useRef} showScanIcon - Set visibility of scan icon.
 * @param {html} saveARSystem - The AR System.
 * @param rating - Rating given in previous screen
 * @param {[]} selectedAction - Contains all params of current action.
 * @return {JSX.Element}
 * @constructor
 */
const EndScreenAction = ({showScanIcon, saveARSystem, rating, selectedAction}) => {
    const {t} = useTranslation();

    useEffect(() => {
        // Update the document title using the browser API
        document.querySelector(".App").style.background = 'var(--green)';
    });

    /**
     * Create badge with answer given in previous screen
     * @param rated - User rating value
     * @return {JSX.Element}
     * @constructor
     */
    const AnswerBadge = (rated) => {
        let reply;

        switch (rated) {
            case 'veryPositive':
                reply =  t('rating.veryPositive');
                break;
            case 'positive':
                reply = t('rating.positive');
                break;
            case 'negative':
                reply = t('rating.negative');
                break;
            case 'veryNegative':
                reply =  t('rating.veryNegative');
                break;
            default:
                reply = "Something went wrong";
        }
        return (
            <span className={`btn-purple-small ${rating}`}>{reply}</span>
        )
    }

    return (
        <div className={'container-end space-between bg-green '}>
            <div className={'reply-badge'}>
                <p>{t('endScreen.yourVote')}</p>
                {AnswerBadge(rating)}
            </div>
            <p>{t('endScreen.othersVote')}</p>
            <DoughnutChart fromRole={false} num={selectedAction.num}/>
            <h2>{t('endScreen.thanks')}</h2>
            <p>→ {t('endScreen.more')}</p>
            <div className={'btn-set-col'}>
                <Link to={'/about-section'} className={'btn btn-purple'}>{t('button.section')} </Link>
                <Link to={'/search'} onClick={() => {
                    showScanIcon.current = true;
                    saveARSystem.unpause()
                }} className={'btn btn-purple'}>{t('button.start')} </Link>
                <Link to={'/evaluation-flow'} className={'btn btn-purple'}>{t('button.evaluation')} </Link>
            </div>
            <FurtherInformationButton t={t}/>
        </div>
    );
};

export default EndScreenAction;
